 
	// - Variables

		// Breakpoints and MediaQueries shorthands
		@import '../../modules/--Config/_Breakpoints'; 
 
		// Colors

			$black :       #000000;
			$white :       #ffffff;
			$yellow : 	   #FCDD00; 
			$gray1 : 	   #F0F0F0;
			$gray2 : 	   #CECECF;
			$gray3 : 	   #757575;
			$gray4 : 	   #2C2C2B;
			$gray5 : 	   #1D1D1B;
			$blue : 	   #005BA7;
			$bluelight :   #9ED6FF;
			$violet : 	   #31112D;
			$red : 		   #C30C0E;
			$redlight :    #D80B11;
			$orange : 	   #E95A0D;
			$orangelight : #F39401;
			$green : 	   #659720;
			$emeraude :    #01766A;
			$forest :      #013E46;
			$grayblue :    #4F707B; 


			// Dominant color
			$activeColor : $yellow; 

			// List of usable colors 

			$ColorList:(  
				// Color. // Hexa
				'black':	  $black,
				'white':	  $white, 
				'yellow':	  $yellow,
				'gray1':	  $gray1,
				'gray2':	  $gray2,
				'gray3':	  $gray3,
				'gray4':	  $gray4,
				'gray5':	  $gray5,
				'blue':		  $blue,
				'bluelight':  $bluelight,
				'violet':	  $violet,
				'red':		  $red,
				'redlight':	  $redlight,
				'orange':	  $orange,
				'orangelight':$orangelight,
				'green':	  $green,
				'emeraude':	  $emeraude,
				'forest':	  $forest,
				'grayblue':	  $grayblue,
			); 
 		  
 
		// Typography
		 
			// Weights 
			$fw-extralight:100;
			.fw-extralight{
				font-weight:$fw-extralight;
			}
			$fw-light:300;
			.fw-light{
				font-weight:$fw-light;
			}
			$fw-regular:400;
			.fw-regular{
				font-weight:$fw-regular;
			}
			$fw-semibold:600;
			.fw-semibold{
				font-weight:$fw-semibold;
			}
			$fw-bold:700;
			.fw-bold{
				font-weight:$fw-bold;
			}
			$fw-black:900;
			.fw-black{
				font-weight:$fw-black;
			}

			// Font base - Without unit to be calculated in mixins
			$fontbase : 16; 

			@function rem($px){
				// rem = $px / 16
				$rem : $px / $fontbase;
				@return #{$rem}rem;
			}
			@function em($px){
				// rem = $px / 16
				$rem : $px / $fontbase;
				@return #{$rem}em;
			}

		// 

		$f-sans : 'Titillium Web', Arial, Helvetica, sans-serif!important;   
		//$f-condensed : 'Roboto Condensed', Arial, Helvetica, sans-serif!important;   

		.f-sans{
			font-family: $f-sans;
		} 
		.f-wide{
			letter-spacing: 0.3em;
		}
		// .f-condensed{
		// 	font-family: $f-condensed;
		// } 

		$f-main : $f-sans;
 
